var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"informative-sec"},[_c('div',{staticClass:"container"},[(typeof _vm.productuspTextSlider != 'undefined')?_c('div',{staticClass:"informative-slider"},[_c('ClientOnly',[_c('VueSlickCarousel',{attrs:{"arrows":false,"dots":false,"slidesToShow":3,"autoplay":true,"responsive":[
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 540,
                        settings: {
                            slidesToShow: 1,
                        },
                    } ]}},_vm._l((_vm.productuspTextSlider.slides),function(slide,index){return _c('div',{key:index,staticClass:"cms-block-content"},[(slide.link && slide.link.substring(0, 4) !== 'http')?_c('b-link',{attrs:{"to":slide.link}},[_c('div',{domProps:{"innerHTML":_vm._s(slide.description)}})]):_vm._e(),(slide.link && slide.link.substring(0, 4) === 'http')?_c('b-link',{attrs:{"href":slide.link,"target":"_blank","rel":"noopener noreferrer"}},[_c('div',{domProps:{"innerHTML":_vm._s(slide.description)}})]):_vm._e(),(!slide.link)?_c('b-link',{staticClass:"usp",attrs:{"to":""}},[(slide.media)?_c('img',{staticClass:"slide-media",attrs:{"src":slide.media.url,"alt":"Slide Image"}}):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(slide.description)}})]):_vm._e()],1)}),0)],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }