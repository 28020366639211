var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"cst-pagination-bar"},[_c('label',[_vm._v("Pagina "),_c('span',[_vm._v(_vm._s(_vm.currentPage))]),_vm._v(" van "),_c('span',[_vm._v(_vm._s(Math.ceil(_vm.totalProds / _vm.prodsPerPage)))])]),_c('div',{staticClass:"view-change ml-0 mobile-work"},[_c('div',{staticClass:"grid-view",on:{"click":function($event){_vm.paginationConf.isGrid = true}}},[_c('i',{staticClass:"fas fa-th-large"})]),_c('div',{staticClass:"list-view",on:{"click":function($event){_vm.paginationConf.isGrid = false}}},[_c('i',{staticClass:"fas fa-list"})])]),_c('b-pagination',{staticClass:"cst-pagination",attrs:{"total-rows":_vm.totalProds,"per-page":_vm.prodsPerPage,"value":_vm.currentPage,"aria-controls":"cat-item"},on:{"change":_vm.changePage},scopedSlots:_vm._u([{key:"page",fn:function(ref){
var page = ref.page;
return [_c('b-link',{staticClass:"page-link",attrs:{"to":_vm.goToPage(page)}},[_vm._v(" "+_vm._s(page)+" ")])]}}])})],1),_c('div',{staticClass:"pagination-bar-down"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"display-drop"},[_c('label',[_vm._v(_vm._s(_vm.$t("view"))+":")]),_c('select',{domProps:{"value":_vm.prodsPerPage},on:{"change":_vm.changePerPage}},[_c('option',{attrs:{"value":"12"}},[_vm._v("12")]),_c('option',{attrs:{"value":"24"}},[_vm._v("24")]),_c('option',{attrs:{"value":"36"}},[_vm._v("36")])])]),_c('div',{staticClass:"view-change desktop-work"},[_c('div',{staticClass:"grid-view",on:{"click":function($event){_vm.paginationConf.isGrid = true}}},[_c('i',{staticClass:"fas fa-th-large"})]),_c('div',{staticClass:"list-view",on:{"click":function($event){_vm.paginationConf.isGrid = false}}},[_c('i',{staticClass:"fas fa-list"})])])]),(_vm.isMobileScreen)?_c('div',{staticClass:"middle"},[_c('div',{staticClass:"filter-btn-mobile",on:{"click":_vm.changeMobileCategoryFilterShow}},[_c('i',{staticClass:"fas fa-filter"}),_c('span',[_vm._v("Filter")])])]):_vm._e(),_c('div',{staticClass:"right"},[_c('CategoryPageContentFilter')],1)]),(_vm.isLoading)?_c('div',{staticClass:"pro-loader"},[_c('div',{staticClass:"reverse-spinner"})]):(_vm.totalProds === 0)?_c('div',[_vm._v("NO Products Found")]):_c('div',{staticClass:"row",class:{
    'grid-view': _vm.paginationConf.isGrid,
    'list-view': !_vm.paginationConf.isGrid,
  }},[_vm._l((_vm.lists),function(product,idx){return [_c('div',{key:product.id,staticClass:"col-12 col-md-6 mb-30 col-lg-4 col-xl-3 cst-12"},[_c('ProductCard',{attrs:{"product":product,"viewType":_vm.paginationConf.isGrid ? 'grid-view' : 'list-view'}})],1),((idx + 1) % 8 == 0 && idx != _vm.lists.length
        )?_c('div',{key:("info-" + (product.sku)),staticClass:"col-12 productSlider"},[_c('InformativeLineProductBlock',{attrs:{"isSlider":true}})],1):_vm._e()]})],2),_c('div',{staticClass:"cst-pagination-bar"},[_c('label',[_vm._v("Pagina "),_c('span',[_vm._v(_vm._s(_vm.currentPage))]),_vm._v(" van "),_c('span',[_vm._v(_vm._s(Math.ceil(_vm.totalProds / _vm.prodsPerPage)))])]),_c('b-pagination',{staticClass:"cst-pagination",attrs:{"total-rows":_vm.totalProds,"per-page":_vm.prodsPerPage,"value":_vm.currentPage,"aria-controls":"cat-item"},on:{"change":_vm.changePage},scopedSlots:_vm._u([{key:"page",fn:function(ref){
        var page = ref.page;
return [_c('b-link',{staticClass:"page-link",attrs:{"to":_vm.goToPage(page)}},[_vm._v(" "+_vm._s(page)+" ")])]}}])})],1),_c('div',{staticClass:"pagination-bar-down"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"display-drop"},[_c('label',[_vm._v(_vm._s(_vm.$t("view"))+":")]),_c('select',{domProps:{"value":_vm.prodsPerPage},on:{"change":_vm.changePerPage}},[_c('option',{attrs:{"value":"12"}},[_vm._v("12")]),_c('option',{attrs:{"value":"24"}},[_vm._v("24")]),_c('option',{attrs:{"value":"36"}},[_vm._v("36")])])]),_c('div',{staticClass:"view-change"},[_c('div',{staticClass:"grid-view",on:{"click":function($event){_vm.paginationConf.isGrid = true}}},[_c('i',{staticClass:"fas fa-th-large"})]),_c('div',{staticClass:"list-view",on:{"click":function($event){_vm.paginationConf.isGrid = false}}},[_c('i',{staticClass:"fas fa-list"})])])]),_c('div',{staticClass:"right"},[_c('CategoryPageContentFilter')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }