<template>
    <div class="informative-sec">
        <div class="container">
            <div class="informative-slider" v-if="typeof productuspTextSlider != 'undefined'">
                <ClientOnly>
                    <VueSlickCarousel :arrows="false" :dots="false" :slidesToShow="3" :autoplay="true" :responsive="[
                        {
                            breakpoint: 1199,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                        {
                            breakpoint: 540,
                            settings: {
                                slidesToShow: 1,
                            },
                        },
                    ]">
                        <div class="cms-block-content" v-for="(slide, index) in productuspTextSlider.slides" :key="index">
                            <b-link :to="slide.link" class="" v-if="slide.link && slide.link.substring(0, 4) !== 'http'">
                                <div v-html="slide.description"></div>
                            </b-link>
                            <b-link :href="slide.link" target="_blank" class="" rel="noopener noreferrer"
                                v-if="slide.link && slide.link.substring(0, 4) === 'http'">
                                <div v-html="slide.description"></div>
                            </b-link>
                            <b-link to="" class="usp" v-if="!slide.link">
                                <img v-if="slide.media" :src="slide.media.url" alt="Slide Image" class="slide-media">
                                <div v-html="slide.description"></div>
                            </b-link>
                        </div>
                    </VueSlickCarousel>
                </ClientOnly>
            </div>
        </div>
    </div>
</template>
  
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ClientOnly from "vue-client-only";
// import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
export default {
    name: "InformativeLineProductBlock",
    components: {
        VueSlickCarousel,
        // cmsBlockSimple,
        ClientOnly,
    },
    props: {
        isSlider: Boolean,
    },
    computed: {
        mobileCheck() {
            if (isMobile || isTablet) {
                return true;
            }
            return false;
        },
        productuspTextSlider() {
            return this.$store.getters["sliders/getSliderByIdentifier"](
                "informative_slider"
            );
        },
    },
};
</script>
  
<style lang="scss" scoped></style>
  