<template>
  <div>
    <div class="cst-pagination-bar">
      <label>Pagina <span>{{ currentPage }}</span> van
        <span>{{ Math.ceil(totalProds / prodsPerPage) }}</span></label>

      <div class="view-change ml-0 mobile-work">
        <div @click="paginationConf.isGrid = true" class="grid-view">
          <i class="fas fa-th-large"></i>
        </div>
        <div @click="paginationConf.isGrid = false" class="list-view">
          <i class="fas fa-list"></i>
        </div>
      </div>

      <!-- pagination -->
      <b-pagination :total-rows="totalProds" :per-page="prodsPerPage" :value="currentPage" @change="changePage"
        class="cst-pagination" aria-controls="cat-item">
        <template #page="{ page }">
          <b-link class="page-link" :to="goToPage(page)">
            {{ page }}
          </b-link>
        </template>
      </b-pagination>
    </div>

    <!-- per page -->
    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select :value="prodsPerPage" @change="changePerPage">
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="36">36</option>
          </select>
        </div>
        <div class="view-change desktop-work">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
      <div class="middle" v-if="isMobileScreen">
        <div @click="changeMobileCategoryFilterShow" class="filter-btn-mobile">
          <i class="fas fa-filter"></i>
          <span>Filter</span>
        </div>
      </div>

      <div class="right">
        <CategoryPageContentFilter />
      </div>
    </div>

    <div class="pro-loader" v-if="isLoading">
      <div class="reverse-spinner"></div>
    </div>
    <div v-else-if="totalProds === 0">NO Products Found</div>
    <div v-else class="row" :class="{
      'grid-view': paginationConf.isGrid,
      'list-view': !paginationConf.isGrid,
    }">
      <template v-for="(product, idx) of lists">
        <div class="col-12 col-md-6 mb-30 col-lg-4 col-xl-3 cst-12" :key="product.id">
          <ProductCard :product="product" :viewType="paginationConf.isGrid ? 'grid-view' : 'list-view'" />
        </div>
        <div class="col-12 productSlider" v-if="(idx + 1) % 8 == 0 && idx != lists.length
          " :key="`info-${product.sku}`">
          <InformativeLineProductBlock :isSlider="true" />
        </div>
      </template>
    </div>

    <div class="cst-pagination-bar">
      <label>Pagina <span>{{ currentPage }}</span> van
        <span>{{ Math.ceil(totalProds / prodsPerPage) }}</span></label>
      <b-pagination :total-rows="totalProds" :per-page="prodsPerPage" :value="currentPage" @change="changePage"
        class="cst-pagination" aria-controls="cat-item">
        <template #page="{ page }">
          <b-link class="page-link" :to="goToPage(page)">
            {{ page }}
          </b-link>
        </template>
      </b-pagination>
    </div>

    <!-- per page -->
    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select :value="prodsPerPage" @change="changePerPage">
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="36">36</option>
          </select>
        </div>
        <div class="view-change">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
      <div class="right">
        <CategoryPageContentFilter />
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { isMobile } from "mobile-device-detect";

import ProductCard from "@/esf_antarctica_lid/core/components/category-product/ProductCard.vue";
import CategoryPageContentFilter from "@/base/core/components/category-product/CategoryPageContentFilter.vue";
import InformativeLineProductBlock from "@/esf_antarctica_lid/core/components/common/InformativeLineProductBlock.vue";

export default {
  name: "FilteredProducts",
  components: {
    ProductCard,
    CategoryPageContentFilter,
    InformativeLineProductBlock
  },

  data() {
    return {
      paginationConf: {
        isGrid: true,

        currentPageNr: 1,
        totalProducts: 0,
        perPage: 12,
      },
    };
  },
  async mounted() {
    const { page } = this.$route.query;
    const path = "/" + this.category.url_path;
    const query = { ...this.$route.query };
    if (page && this.totalPages < parseInt(page)) {
      delete query["page"];
    }
    const pageSize = config.products?.perPage || 12;
    const perPage = this.$store.getters["category/getProductsPerPage"];
    if (pageSize != perPage) query["page-size"] = perPage;
    this.$router.replace({ path, query });
  },
  computed: {
    informativeSlider() {
      const data =
        this.$store.getters["sliders/getSliderByIdentifier"](
          "informative_slider"
        );
      return data;
    },
    ...mapGetters({
      getMobileCategoryFilterShow: "mobileRes/getMobileCategoryFilterShow",
    }),
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    isLoading() {
      return this.$store.getters["category/getLoadProducts"];
    },
    showProducts() {
      return this.$store.getters["category/getShowProducts"];
    },
    totalProducts() {
      return this.$store.getters["category/getShowProducts"].length;
    },
    currentPage() {
      return this.$store.getters["category/getCurrentPage"];
    },
    totalPages() {
      return this.$store.getters["category/getTotalPages"];
    },
    prodsPerPage() {
      return this.$store.getters["category/getProductsPerPage"];
    },
    totalProds() {
      return this.$store.getters["category/getTotalProducts"];
    },
    lists() {
      if (this.$store.getters["category/getShowProducts"].length === 0) {
        return [];
      }

      const items = this.$store.getters["category/getShowProducts"];

      const filterdInStock = items.filter(
        (item) => item.stock_status == "IN_STOCK"
      );
      const outOfStock = items.filter(
        (item) => item.stock_status != "IN_STOCK"
      );

      const newProducts = [...filterdInStock, ...outOfStock];
      return newProducts;
    },
    isMobileScreen() {
      return isMobile;
    },
    priceFilterMin() {
      return this.$store.getters["category/getPriceFilterMin"];
    },
    priceFilterMax() {
      return this.$store.getters["category/getPriceFilterMax"];
    },
  },
  methods: {
    ...mapActions({
      changeMobileCategoryFilterShow:
        "mobileRes/changeMobileCategoryFilterShow",
    }),
    changePage(pageNo) {
      this.$store.commit("category/setCurrentPage", pageNo);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (pageNo != 1) query["page"] = pageNo;
      else delete query["page"];

      this.$router.replace({ path, query });
    },
    goToPage(page) {
      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];

      return { path, query };
    },
    changePerPage(event) {
      const perPage = +event.target.value;
      const { path } = this.$route;
      const query = { ...this.$route.query };

      const pageSize = config.products?.perPage;

      if (perPage != pageSize) query["page-size"] = perPage;
      else delete query["page-size"];
      delete query["page"];

      this.$router.replace({ path, query });
      this.$store.commit("category/setProductsPerPage", perPage);
    },
    backToTop() {
      window.smoothscroll();
      this.$emit("scrolled");
    },
  },
  watch: {
    // currentPage(page) {
    //   const { path } = this.$route;
    //   const query = { ...this.$route.query, page };
    //   this.$router.replace({ path, query });
    // },
  },
};
</script>

<style lang="scss" scoped></style>
