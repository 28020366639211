<template>
    <section class="bottom-txt">
        <div class="extra-info" v-html="category.description"></div>
    </section>
</template>

<script>
export default {
    name: "CategoryDescription",
    computed: {
        category() {
            return this.$store.getters["category/getCurrentCategory"];
        },
    }
}
</script>
