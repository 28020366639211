<template>
  <section class="best-sellers">
    <span class="best-seller--category">{{ category.name }}</span>
    <div class="category-description" v-if="category.description" v-html="category.description"></div>
    <span v-if="bestsellers.length > 0" class="section-title">{{
      $t("bestsellers_for_you")
    }}</span>
    <div v-if="bestsellers.length > 0" class="row">
      <div
        class="col-6 col-md-4 position-relative best-seller--card-holder"
        v-for="product of bestsellers.slice(0, 4)"
        :key="product.sku"
      >
        <b-link
          v-if="
            $store.getters['wishlist/isProductInWishlist'](product.sku) &&
            isLoggedIn
          "
          class="add-to-wishlist"
          @click="
            onClickWishListIcon(
              product.sku,
              product.__typename,
              product.url_key
            )
          "
        >
          <div
            class="heart"
            v-if="$store.getters['wishlist/isProductInWishlist'](product.sku)"
          >
            <img src="@/base/assets/imgs/heart.png" alt="heart" />
          </div>
          <i class="lnr lnr-heart" v-else />
        </b-link>
        <b-link class="bestsellers-card" :to="`/` + product.url_key">
          <div
            class="bestsellers-card--img"
            :style="{ backgroundImage: `url(${product.thumbnail.url})` }"
          >
            <div class="actionbuttons">
              <span class="sale-txt" v-if="product.sale == 1">Aanbieding</span>
              <span class="new-txt" v-if="product.new == 1">Nieuw</span>
            </div>
          </div>
          <span class="bestsellers-card--product-name">{{ product.name }}</span>

          <span
            v-if="
              product.price_range.minimum_price.final_price.value ==
              product.price_range.maximum_price.final_price.value
            "
            class="d-block bestsellers-card--price"
            >{{
              formatCurrency(
                product.price_range.minimum_price.final_price.value
              )
            }}</span
          >
          <span
            v-if="
              product.price_range.minimum_price.final_price.value !=
              product.price_range.maximum_price.final_price.value
            "
            class="d-block bestsellers-card--price"
            >{{
              formatCurrency(
                product.price_range.minimum_price.final_price.value
              )
            }}
            -
            {{
              formatCurrency(
                product.price_range.maximum_price.final_price.value
              )
            }}</span
          >

          <span
            class="d-block bestsellers-card--sale-comment"
            v-if="product.price_range.minimum_price.discount.percent_off > 0"
            >{{ $t("discount") }}
            {{
              product.price_range.minimum_price.discount.percent_off.toFixed(0)
            }}%</span
          >
          <span
            class="d-block bestsellers-card--sale-comment"
            style="visibility: hidden"
            v-if="product.price_range.minimum_price.discount.percent_off == 0"
            >{{ $t("discount") }}
            {{
              product.price_range.minimum_price.discount.percent_off.toFixed(0)
            }}%</span
          >
        </b-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BestSellers",
  methods: {
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    onClickWishListIcon(productId, typename, url_key) {
      if (this.$store.getters["wishlist/isProductInWishlist"](productId) == true) {
        this.$store.dispatch("user/setProductWishlistStatus", {
          sku: productId,
          parentSku: null,
        });
      } else {
        if (typename == "SimpleProduct") {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: productId,
            parentSku: null,
          });
        } else {
          const msg = {
            type: "danger",
            title: this.$t("wishlist error"),
            text: this.$t("wishlist_select_options"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push("/" + url_key);
        }
      }
    },
  },
  computed: {
    bestsellers() {
      return this.$store.getters["category/getBestSellersSimple"];
    },
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
};
</script>

<style lang="scss" scoped>
.add-to-wishlist {
  position: absolute;
  left: 7px;
  top: 3px;
  font-size: 21px;
  z-index: 9;
  width: 22px;
  height: 28px;

  &:hover {
    text-decoration: none;
    ~ .bestsellers-card {
      color: $black !important;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
    }
  }

  .heart {
    position: absolute;
    top: -3px;
    right: 0;
    z-index: 9;
  }
}

.best-sellers {
  border: 1px solid $borderColor;
  position: relative;
  margin-top: 56px;
  padding: 37px 15px 12px;
  margin-bottom: 30px;
}

.best-seller--category {
  font-size: 24px;
  letter-spacing: -0.025em;
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  background: $primaryColor;
  color: $white;
  padding: 6px 10px;
  min-width: 213px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

.section-title {
  font-size: 18px;
  font-weight: normal;
  display: block;
  text-align: center;
}

.bestsellers-card {
  display: block;
  padding: 10px 18px;
  position: relative;
  height: calc(100% - 5px);
  transition: 0.5s;
  color: $black !important;
  border: 1px solid $borderColor;

  &:hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
    text-decoration: none;
    .bestsellers-card--product-name {
      text-decoration: none;
    }
  }

  &--img {
    width: 100%;
    height: 200px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 12px;
    position: relative;
  }

  &--product-name {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 6px;
    height: 40px;
    @include truncat-3;
  }

  &--price {
    display: block;
    font-size: 16px;
    font-weight: 500;
  }

  &--sale-comment {
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: $red;
    padding-top: 5px;
  }
}

.best-seller--card-holder {
  margin-top: 30px;
  .actionbuttons {
    position: absolute;
    top: calc(100% - 20px);
    left: 3px;

    .sale-txt,
    .new-txt {
      background: $red;
      font-weight: 500;
      color: $white;
      font-size: 14px;
      padding: 1px 12px;
      margin-right: 1px;
    }
    .new-txt {
      background: $primaryColor;
    }
  }

  &:nth-child(n + 4) {
    display: none;
  }
  .actionbuttons {
    position: absolute;
    top: calc(100% - 20px);
    left: 3px;

    .sale-txt,
    .new-txt {
      background: $red;
      font-weight: 500;
      color: $white;
      font-size: 14px;
      padding: 1px 12px;
      margin-right: 1px;
    }
    .new-txt {
      background: $primaryColor;
    }
  }
}
@media screen and (max-width: 1024px) {
  .best-seller {
    &--category {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 670px) {
  .best-seller {
    &--category {
      font-size: 16px;
    }
  }
}
</style>
