<template>
  <div>
    <!-- <AdvertisingBanner  class="col-12 my-30 mt-lg-0"/> -->
    <BestSellers />
    <CategoryPageContent />
    <!-- <CompareButton></CompareButton> -->
  </div>
</template>

<script>
import CategoryPageContent from "@/esf_antarctica_lid/core/components/category-product//CategoryPageContent.vue";
import BestSellers from "@/esf_antarctica_lid/core/components/category-product//BestSellers.vue";
// import CompareButton from "@/base/core/components/common/CompareButton.vue";
export default {
  name: "ProductListingV2",
  components: {
    BestSellers,
    CategoryPageContent,
    // CompareButton,
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    bestsellers() {
      return this.$store.getters["category/getBestSellersSimple"];
    },
  },
};
</script>

<style lang="scss" scoped>
h3,
.h3 {
  font-weight: 400;
  margin-bottom: 10px;
}

.bottom-txt {
  font-size: 12px;
  line-height: 1.72;
  padding-bottom: 14px;
  // padding-top: 25px;
}
.category-name-right {
  font-size: 16px;
  font-weight: 600;
}
</style>
