var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"best-sellers"},[_c('span',{staticClass:"best-seller--category"},[_vm._v(_vm._s(_vm.category.name))]),(_vm.bestsellers.length > 0)?_c('span',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("bestsellers_for_you")))]):_vm._e(),(_vm.bestsellers.length > 0)?_c('div',{staticClass:"row"},_vm._l((_vm.bestsellers.slice(0, 4)),function(product){return _c('div',{key:product.sku,staticClass:"col-6 col-md-4 position-relative best-seller--card-holder"},[(
          _vm.$store.getters['user/isProductInWishlist'](product.sku) &&
          _vm.isLoggedIn
        )?_c('b-link',{staticClass:"add-to-wishlist",on:{"click":function($event){return _vm.onClickWishListIcon(
            product.sku,
            product.__typename,
            product.url_key
          )}}},[(_vm.$store.getters['user/isProductInWishlist'](product.sku))?_c('div',{staticClass:"heart"},[_c('img',{attrs:{"src":require("@/base/assets/imgs/heart.png"),"alt":"heart"}})]):_c('i',{staticClass:"lnr lnr-heart"})]):_vm._e(),_c('b-link',{staticClass:"bestsellers-card",attrs:{"to":"/" + product.url_key}},[_c('div',{staticClass:"bestsellers-card--img",style:({ backgroundImage: ("url(" + (product.thumbnail.url) + ")") })},[_c('div',{staticClass:"actionbuttons"},[(product.sale == 1)?_c('span',{staticClass:"sale-txt"},[_vm._v("Aanbieding")]):_vm._e(),(product.new == 1)?_c('span',{staticClass:"new-txt"},[_vm._v("Nieuw")]):_vm._e()])]),_c('span',{staticClass:"bestsellers-card--product-name"},[_vm._v(_vm._s(product.name))]),(
            product.price_range.minimum_price.final_price.value ==
            product.price_range.maximum_price.final_price.value
          )?_c('span',{staticClass:"d-block bestsellers-card--price"},[_vm._v(_vm._s(_vm.formatCurrency( product.price_range.minimum_price.final_price.value )))]):_vm._e(),(
            product.price_range.minimum_price.final_price.value !=
            product.price_range.maximum_price.final_price.value
          )?_c('span',{staticClass:"d-block bestsellers-card--price"},[_vm._v(_vm._s(_vm.formatCurrency( product.price_range.minimum_price.final_price.value ))+" - "+_vm._s(_vm.formatCurrency( product.price_range.maximum_price.final_price.value )))]):_vm._e(),(product.price_range.minimum_price.discount.percent_off > 0)?_c('span',{staticClass:"d-block bestsellers-card--sale-comment"},[_vm._v(_vm._s(_vm.$t("discount"))+" "+_vm._s(product.price_range.minimum_price.discount.percent_off.toFixed(0))+"%")]):_vm._e(),(product.price_range.minimum_price.discount.percent_off == 0)?_c('span',{staticClass:"d-block bestsellers-card--sale-comment",staticStyle:{"visibility":"hidden"}},[_vm._v(_vm._s(_vm.$t("discount"))+" "+_vm._s(product.price_range.minimum_price.discount.percent_off.toFixed(0))+"%")]):_vm._e()])],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }