<template>
  <div>
    <span class="close-filter" v-if="isMobleScreen && getMobileCategoryFilterShow"
      @click="changeMobileCategoryFilterShow">
      <b-icon icon="x"></b-icon>
    </span>

    <div class="category filter-box" v-if="!dontShowActiveFilters && categories.length > 0">
      <span class="filter--block-heading">{{ $t("category") }}</span>
      <ul class="category-list">
        <li v-for="categoryItem in categories" :key="categoryItem.id">
          <b-link :to="`/` + categoryItem.url_path">{{
            categoryItem.name
          }}</b-link>
        </li>
      </ul>
    </div>

    <div class="active-filters filter-box" v-if="isPriceFilterActive || filterItemsSelected.length">
      <ClientOnly>
        <b-link class="filter--block-heading filter--collapse" v-b-toggle.active-filters>{{ $t("used_filters") }} <i
            class="fas fa-chevron-right"></i></b-link>
      </ClientOnly>
      <b-collapse visible id="active-filters">
        <ul class="active-filters-list">
          <li v-if="isPriceFilterActive">
            <ClientOnly>
              <b-link @click="removePriceFilter">
                <i class="fas fa-times"></i>
              </b-link>
            </ClientOnly>
            <span class="active-filter--name">
              {{ `${priceFilterLabel ? priceFilterLabel : "Prijs"}` }}:
              {{ priceFilter[0] + " - " + priceFilter[1] }}
            </span>
          </li>
          <li v-for="(activeFilterItem, index) of filterItemsSelected" :key="index">
            <ClientOnly>
              <b-link @click="removeSelectedItem(activeFilterItem.value)">
                <i class="fas fa-times"></i>
              </b-link>
            </ClientOnly>
            <span class="active-filter--name">
              {{ activeFilterItem.type_label }}:
              {{ activeFilterItem.label }}
            </span>
          </li>
        </ul>
      </b-collapse>
    </div>
    <div class="filter-box" v-for="prodFilter of productFilters" :key="prodFilter.attribute_code">
      <div class="price" v-if="prodFilter.attribute_code == 'price'">
        <ClientOnly>
          <b-link class="filter--block-heading filter--collapse" v-b-toggle.price-filter>
            {{ $t(prodFilter.label) }}
            <!-- {{ prodFilter.label }} -->
            <i class="fas fa-chevron-right"></i>
          </b-link>
        </ClientOnly>
        <b-collapse visible id="price-filter">
          <div class="range-slider">
            <ClientOnly>
              <VueRangeSlider :min="priceFilterMin" :max="priceFilterMax" :enable-cross="enableCross"
                :formatter="formatter" v-model="priceFilter" :logarithmic="true" :tooltip="null" :lazy="true">
              </VueRangeSlider>

              <!--
              <vue-range-slider
                :min="prodFilter.min"
                :max="prodFilter.max"
                :enable-cross="enableCross"
                :formatter="formatter"
                v-model="priceFilter"
              >
              </vue-range-slider>
              -->
            </ClientOnly>

            <div class="range-values d-flex justify-content-between">
              <span>€{{ priceFilter[0] }}</span>
              <span>€{{ priceFilter[1] }}</span>
            </div>
          </div>
        </b-collapse>
      </div>

      <div class="brands" v-if="prodFilter.attribute_code != 'size' &&
        prodFilter.attribute_code != 'price'
        ">
        <ClientOnly>
          <b-link class="filter--block-heading filter--collapse"
            v-b-toggle="`brands-filter-${prodFilter.attribute_code}`">
            <!-- {{ $t(prodFilter.label) }} -->
            {{ prodFilter.label }}
            <i class="fas fa-chevron-right"></i>
          </b-link>
        </ClientOnly>
        <b-collapse :id="`brands-filter-${prodFilter.attribute_code}`" class="brands-filter">
          <div class="d-flex justify-content-between align-items-center"
            v-for="(brandsFilterItem, index) in prodFilter.options" :key="index">
            <label :for="brandsFilterItem.value" class="d-flex align-items-center">
              <input type="checkbox" :key="brandsFilterItem.value" :name="brandsFilterItem.label"
                :id="brandsFilterItem.value" :ref="brandsFilterItem.value" :value="brandsFilterItem.value" @click="
                  onChangeFilter($event, {
                    attribute_code: prodFilter.attribute_code,
                    type_label: prodFilter.label,
                    value: brandsFilterItem.value,
                    label: brandsFilterItem.label,
                  })
                  " />
              <span class="fake-input"></span>
              <span class="fake-label">{{ brandsFilterItem.label }}</span>
            </label>
            <span class="number-available">({{ brandsFilterItem.count }})</span>
          </div>
        </b-collapse>
      </div>

      <div class="size" v-if="prodFilter.attribute_code == 'size'">
        <b-link class="filter--block-heading filter--collapse mb-15" v-b-toggle.size-filter>{{ $t("size")
        }}</b-link>
        <b-collapse visible id="size-filter" class="size-filter">
          <ul class="available-sizes">
            <li v-for="(sizeFilterItem, index) in prodFilter.options" :key="index">
              <label :for="sizeFilterItem.name" class="d-flex align-items-center">
                <input type="checkbox" :id="sizeFilterItem.value" :key="sizeFilterItem.value" :name="sizeFilterItem.label"
                  :ref="sizeFilterItem.value" :value="sizeFilterItem.value" @change="
                    onChangeFilter($event, {
                      attribute_code: prodFilter.attribute_code,
                      type_label: prodFilter.label,
                      value: sizeFilterItem.value,
                      label: sizeFilterItem.label,
                    })
                    " />
                <span class="fake-input"></span>
                <span class="fake-label">{{ sizeFilterItem.label }}</span>
              </label>
            </li>
          </ul>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import ClientOnly from "vue-client-only";
import { mapActions } from "vuex";
import { isMobile } from "mobile-device-detect";
import { mapGetters } from "vuex";
import VueRangeSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import "vue-slider-component/dist-css/vue-slider-component.css";
export default {
  name: "ProductFilterSidebar",
  components: {
    ClientOnly,
    VueRangeSlider,
  },
  props: {
    dontShowActiveFilters: {
      type: Boolean,
    },
  },

  created() {
    this.min = 0;
    this.max = 250;
    this.enableCross = false;
    this.formatter = "€ {value}";
    this.tooltipDir = "bottom";
  },
  methods: {
    ...mapActions({
      changeMobileCategoryFilterShow:
        "mobileRes/changeMobileCategoryFilterShow",
    }),
    onChangeFilter(event, filter) {
      if (typeof filter != "undefined" && typeof filter.value != "undefined") {
        if (event.target.checked == true) {
          this.$store.commit("category/setActiveFilter", filter);
        } else {
          this.$store.commit("category/removeActiveFilter", filter);
        }
        this.updateUrlFilters();
      }
    },
    removePriceFilter() {
      const priceFilter = [this.priceFilterMin, this.priceFilterMax];
      this.$store.commit("category/setPriceFilter", priceFilter);

      const path = "/" + this.category.url_path;
      const query = { ...this.$route.query };
      delete query["filter-price"];
      delete query["page"];

      this.$router.replace({ path, query });
    },
    removeSelectedItem(id) {
      const filter = { value: id };
      if (this.$refs[id] && this.$refs[id][0]) {
        this.$refs[id][0].checked = false;
      }
      this.$store.commit("category/removeActiveFilter", filter);
      this.updateUrlFilters();
    },
    toggleFilter(event) {
      this.$emit("clicked", event);
    },
    updateUrlFilters() {
      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.value);
        } else {
          activeFilters[val.attribute_code] = [val.value];
        }
      });

      const path = "/" + this.category.url_path;
      const query = { ...this.$route.query };

      for (const q in query) {
        if (q.startsWith("filter-") && q !== "filter-price") {
          delete query[q];
        }
      }
      for (const attr in activeFilters) {
        query[`filter-${attr}`] = activeFilters[attr].toString();
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
  },
  data() {
    return {
      value: [0, 200],
      sliderIsLoaded: false,
      bla: false,
      minAngle: 10,
      maxAngle: 30,
      filterExpanded: false,
    };
  },
  computed: {
    ...mapGetters({
      getMobileCategoryFilterShow: "mobileRes/getMobileCategoryFilterShow",
    }),
    isPriceFilterActive() {
      return this.$store.getters["category/getIsPriceFilterActive"];
    },
    isMobleScreen() {
      return isMobile;
    },
    filterItemsSelected() {
      return this.$store.getters["category/getActiveFilter"];
    },
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    priceFilterLabel() {
      if (this.productFilters) {
        const priceFilter = this.productFilters.find(
          (filter) => filter.attribute_code == "price"
        );
        return priceFilter && priceFilter.label;
      }
      return null;
    },
    priceFilter: {
      get: function () {
        return this.$store.getters["category/getPriceFilter"];
      },
      set: function (val) {
        Logger.debug("set", "setPriceFilter", val)();
        this.$store.commit("category/setPriceFilter", val);

        const path = "/" + this.category.url_path;
        const query = { ...this.$route.query };
        if (this.isPriceFilterActive) {
          query["filter-price"] = `${val[0]}-${val[1]}`;
        } else {
          delete query["filter-price"];
        }
        delete query["page"];

        this.$router.replace({ path, query });
      },
    },
    priceFilterMin() {
      return this.$store.getters["category/getPriceFilterMin"];
    },
    priceFilterMax() {
      return this.$store.getters["category/getPriceFilterMax"];
    },
    productFilters: {
      get: function () {
        return this.$store.getters["category/getProductFilters"];
      },
      set: function (val) {
        Logger.debug("set", "productFilters", val)();
        //this.$store.commit("category/setProductFilters", val);
      },
    },
    categories() {
      return this.category.children;
    },
  },
  updated() {
    if (this.filterItemsSelected && this.filterItemsSelected.length) {
      this.filterItemsSelected.forEach((filter) => {
        if (this.$refs[filter.value] && this.$refs[filter.value][0]) {
          this.$refs[filter.value][0].checked = true;
        }
      });
    }
  },
  watch: {
    filterItemsSelected: function () { },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease 0.4s;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.filter-top {
  position: relative;

  .lnr-cross {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -6px;
    font-size: 20px;
  }
}

.filter-collapse-heading {
  display: block;
  color: #111f44;
  font-size: 25px;
  font-weight: normal;
  padding: 15px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid $borderColor;
}

.filter-collapse {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: $white;
  z-index: 99999;
  width: 90%;
  max-width: 370px;
  overflow-y: auto;
  padding: 0 15px;
}

.filter--block-heading {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  color: $black;

  &.filter--collapse {
    position: relative;

    svg {
      font-size: 12px;
      transition: 0.5s;
    }

    // &:before {
    //   font-family: "Font Awesome 5 Free Solid";
    //   content: "\f0d7";
    //   position: absolute;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   right: 1px;
    //   font-size: 13px;
    // }

    &.not-collapsed {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

ul {
  &.category-list {
    font-size: 15px;
    list-style: none;
    padding: 0;
    margin-bottom: 10px;

    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }

      a {
        color: $black;
      }
    }
  }
}

.active-filters {
  position: relative;

  .delete-active-filters {
    position: absolute;
    top: 5px;
    right: -2px;
  }
}

.active-filters-list {
  padding-bottom: 8px;

  li {
    background-color: $borderColor;
    display: inline-flex;
    align-items: center;
    margin: 2px;
    padding: 3px 5px;
    border-radius: 2px;
    font-size: 13px;

    &:hover {
      a {
        color: $red;
      }
    }
  }

  a {
    display: inline-flex;
    margin-right: 5px;
    color: $black;
  }
}

.brands-filter {
  padding-top: 0px;

  >div {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 10px;
  }

  label {
    margin-bottom: 0;
    position: relative;
  }

  .fake-input {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: $white;
    vertical-align: middle;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;
    border: 1px solid $borderColor;
    border-radius: 3px;
  }

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;

    +.fake-input:before {
      content: "";
      width: 10px;
      height: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      border-style: solid;
      border-color: $white;
      border-width: 0 0 2px 2px;
      opacity: 0;
      transform: rotate(-45deg) translate(0px, 0px);
      margin: -3px 0 0 -5px;
    }

    &:checked {
      +.fake-input:before {
        opacity: 1;
      }

      ~.fake-input {
        background: $primaryColor;
        border: none;
      }
    }
  }
}

.available-sizes {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 -0.45rem;
  padding-bottom: 20px;

  li {
    width: 45px;
    height: 25px;
    margin: 0 0.45rem 0.9rem;

    input {
      position: fixed;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;

      &:checked~.fake-label {
        color: $white !important;
      }
    }

    .fake-input {}

    .fake-label {
      display: block;
      border: 1px solid $borderColor;
      transition: none;
      color: $black;
      width: 45px;

      &:hover {
        color: $white !important;
      }

      &:focus {
        background: none;
        border-color: $borderColor;
        color: $black !important;
        transition: background 0.3s ease-in-out;
      }
    }

    a {
      display: block;
      border: 1px solid $borderColor;
      transition: none;
      color: $black !important;

      &:hover {
        color: $white !important;
      }

      &:focus {
        background: none;
        border-color: $borderColor;
        color: $black !important;
        transition: background 0.3s ease-in-out;
      }
    }

    &.selected {
      a {
        color: $white !important;

        &:focus {
          color: $white !important;
        }
      }
    }
  }
}

.number-available {
  font-size: 12px;
  font-weight: 600;
}

.filter-box:not(:last-child) {
  border-bottom: 1px solid $borderColor;
}

.slider-tooltip,
.merged-tooltip.slider-tooltip-wrap.slider-tooltip-bottom {
  display: none !important;
}

.delete-active-filters {
  margin-top: 25px;
}
</style>