<template>
  <div class="container">
    <!-- <CatBanner /> -->

    <div class="cst-pagination-bar">
      <label
        >Pagina <span>{{ paginationConf.currentPageNr }}</span> van
        <span>{{ Math.ceil(rows / paginationConf.perPage) }}</span></label
      >

      <!-- pagination -->
      <b-pagination
        v-model="paginationConf.currentPageNr"
        :total-rows="rows"
        :per-page="paginationConf.perPage"
        class="cst-pagination"
        aria-controls="cat-item"
        @change="tmp"
      >
        <template #page="{ page }">
          <b-link class="page-link">
            {{ page }}
          </b-link>
        </template>
      </b-pagination>
    </div>

    <!-- per page -->
    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}: </label>
          <select v-model.number="paginationConf.perPage">
            <!-- <option value="1">1</option> -->
            <option value="1">1</option>
            <option value="9">9</option>
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="72">72</option>
            <option value="98">98</option>
            <option value="120">120</option>
          </select>
        </div>
        <div class="view-change">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- v-if="category.is_anchor == 1" -->
        <CategoryPageContentFilter />
        <!-- <CatagoryFilter v-if="category.is_anchor == 0" /> -->
      </div>
    </div>

    <b-row
      v-if="category.children_count > 0"
      :class="{
        'grid-view': paginationConf.isGrid,
        'list-view': !paginationConf.isGrid,
      }"
    >
      <div
        id="cat-item"
        class="col-6 col-lg-3 col-md-4 cst-12"
        v-for="(category, i) of lists"
        :key="`${category} ${i}`"
      >
        <b-link class="category-link" :to="`/` + category.url_path">
          <div class="category-box">
            <div class="img">
              <b-img-lazy
                v-if="category.image != null"
                :src="category.image"
                :alt="category.name"
                fluid
              ></b-img-lazy>
            </div>
            <div class="text">
              <p>{{ category.name }}</p>
            </div>
            <div class="list-action-btn">
              <a href="javascript:void(0)" class="c-btn c-btn-primary">{{
                $t("view_category")
              }}</a>
            </div>
          </div>
        </b-link>
      </div>
    </b-row>

    <div class="cst-pagination-bar">
      <label
        >Pagina <span>{{ paginationConf.currentPageNr }}</span> van
        <span>{{ Math.ceil(rows / paginationConf.perPage) }}</span></label
      >
      <!-- pagination -->
      <b-pagination
        v-model="paginationConf.currentPageNr"
        :total-rows="rows"
        :per-page="paginationConf.perPage"
        class="cst-pagination"
        aria-controls="cat-item"
        @change="tmp"
      >
        <template #page="{ page }">
          <b-link class="page-link">
            {{ page }}
          </b-link>
        </template>
      </b-pagination>
    </div>

    <!-- per page -->
    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select v-model.number="paginationConf.perPage">
            <option value="1">1</option>
            <option value="9">9</option>
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="72">72</option>
            <option value="98">98</option>
            <option value="120">120</option>
          </select>
        </div>
        <div class="view-change">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
    </div>

    <b-row v-if="category.children_count == 0">
      <div style="width: 100%; text-align: center">
        {{ $t("no_products_found") }}
      </div>
    </b-row>
  </div>
</template>
<script>
// import CatBanner from "@/base/core/components/category-product/CatBanner.vue";
// import CatagoryFilter from "@/base/core/components/category-product/CatagoryFilter.vue";
import CategoryPageContentFilter from "@/base/core/components/category-product/CategoryPageContentFilter.vue";

export default {
  name: "CategoryOverview",

  components: {
    // CatBanner,
    // CatagoryFilter,
    CategoryPageContentFilter,
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    categories() {
      return this.category.children;
    },
    rows() {
      return this.categories.length;
    },
    lists() {
      if (this.categories.length === 0) {
        return [];
      }
      const items = this.categories;
      // Return just page of items needed
      return items.slice(
        (this.paginationConf.currentPageNr - 1) * this.paginationConf.perPage,
        this.paginationConf.currentPageNr * this.paginationConf.perPage
      );
    },
  },
  data() {
    return {
      //categories: mockCategory,
      paginationConf: {
        isGrid: true,
        currentPageNr: 1,
        totalCount: 0,
        perPage: 12,
      },
    };
  },
  methods: {
    tmp(id) {
      this.showOrders = false;
      setTimeout(() => {
        this.showOrders = true;
        this.paginationConf.currentPageNr = id;
      }, 0.001);
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-bottom: 8px;
}

.container {
  max-width: 1200px;
}

.row {
  margin: 0 -17px 20px;
}

.col {
  display: flex;
  flex-basis: auto;
  padding: 16px 17px;
  flex-grow: 0;
  width: 50%;
}

.category-link {
  display: flex;
  width: 100%;
}

.category-box {
  border: 1px solid $borderColor;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 18px;
  text-align: center;
  font-size: 12px;
  line-height: 1.1;
  margin-bottom: 30px;
  transition: 0.5s;
  &:hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
  }

  p {
    margin-bottom: 0;
  }
}

.img {
  height: 185px;
  display: flex;
  align-items: center;
  z-index: -1;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.text {
  width: 100%;
  font-weight: 600;
  color: #000;
  font-size: 15px;
  padding: 15px 0px;
  height: calc(100% - 185px);
  display: block;
  text-align: left;
}
@media screen and (max-width: 670px) {
  .category-box {
    padding: 10px;
    .img {
      height: 130px;
    }
    .text {
      height: auto;
      padding-bottom: 0;
      word-break: break-word;
    }
  }
}
.category-name-right {
  font-size: 16px;
  font-weight: 600;
}
</style>
