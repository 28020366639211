var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"cst-pagination-bar"},[_c('label',[_vm._v("Pagina "),_c('span',[_vm._v(_vm._s(_vm.paginationConf.currentPageNr))]),_vm._v(" van "),_c('span',[_vm._v(_vm._s(Math.ceil(_vm.rows / _vm.paginationConf.perPage)))])]),_c('b-pagination',{staticClass:"cst-pagination",attrs:{"total-rows":_vm.rows,"per-page":_vm.paginationConf.perPage,"aria-controls":"cat-item"},on:{"change":_vm.tmp},scopedSlots:_vm._u([{key:"page",fn:function(ref){
var page = ref.page;
return [_c('b-link',{staticClass:"page-link"},[_vm._v(" "+_vm._s(page)+" ")])]}}]),model:{value:(_vm.paginationConf.currentPageNr),callback:function ($$v) {_vm.$set(_vm.paginationConf, "currentPageNr", $$v)},expression:"paginationConf.currentPageNr"}})],1),_c('div',{staticClass:"pagination-bar-down"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"display-drop"},[_c('label',[_vm._v(_vm._s(_vm.$t("view"))+": ")]),_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.paginationConf.perPage),expression:"paginationConf.perPage",modifiers:{"number":true}}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.paginationConf, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("1")]),_c('option',{attrs:{"value":"9"}},[_vm._v("9")]),_c('option',{attrs:{"value":"12"}},[_vm._v("12")]),_c('option',{attrs:{"value":"24"}},[_vm._v("24")]),_c('option',{attrs:{"value":"48"}},[_vm._v("48")]),_c('option',{attrs:{"value":"72"}},[_vm._v("72")]),_c('option',{attrs:{"value":"98"}},[_vm._v("98")]),_c('option',{attrs:{"value":"120"}},[_vm._v("120")])])]),_c('div',{staticClass:"view-change"},[_c('div',{staticClass:"grid-view",on:{"click":function($event){_vm.paginationConf.isGrid = true}}},[_c('i',{staticClass:"fas fa-th-large"})]),_c('div',{staticClass:"list-view",on:{"click":function($event){_vm.paginationConf.isGrid = false}}},[_c('i',{staticClass:"fas fa-list"})])])]),_c('div',{staticClass:"right"},[_c('CategoryPageContentFilter')],1)]),(_vm.category.children_count > 0)?_c('b-row',{class:{
      'grid-view': _vm.paginationConf.isGrid,
      'list-view': !_vm.paginationConf.isGrid,
    }},_vm._l((_vm.lists),function(category,i){return _c('div',{key:(category + " " + i),staticClass:"col-6 col-lg-3 col-md-4 cst-12",attrs:{"id":"cat-item"}},[_c('b-link',{staticClass:"category-link",attrs:{"to":"/" + category.url_path}},[_c('div',{staticClass:"category-box"},[_c('div',{staticClass:"img"},[(category.image != null)?_c('b-img-lazy',{attrs:{"src":category.image,"alt":category.name,"fluid":""}}):_vm._e()],1),_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(category.name))])]),_c('div',{staticClass:"list-action-btn"},[_c('a',{staticClass:"c-btn c-btn-primary",attrs:{"href":"javascript:void(0)"}},[_vm._v(_vm._s(_vm.$t("view_category")))])])])])],1)}),0):_vm._e(),_c('div',{staticClass:"cst-pagination-bar"},[_c('label',[_vm._v("Pagina "),_c('span',[_vm._v(_vm._s(_vm.paginationConf.currentPageNr))]),_vm._v(" van "),_c('span',[_vm._v(_vm._s(Math.ceil(_vm.rows / _vm.paginationConf.perPage)))])]),_c('b-pagination',{staticClass:"cst-pagination",attrs:{"total-rows":_vm.rows,"per-page":_vm.paginationConf.perPage,"aria-controls":"cat-item"},on:{"change":_vm.tmp},scopedSlots:_vm._u([{key:"page",fn:function(ref){
    var page = ref.page;
return [_c('b-link',{staticClass:"page-link"},[_vm._v(" "+_vm._s(page)+" ")])]}}]),model:{value:(_vm.paginationConf.currentPageNr),callback:function ($$v) {_vm.$set(_vm.paginationConf, "currentPageNr", $$v)},expression:"paginationConf.currentPageNr"}})],1),_c('div',{staticClass:"pagination-bar-down"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"display-drop"},[_c('label',[_vm._v(_vm._s(_vm.$t("view"))+":")]),_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.paginationConf.perPage),expression:"paginationConf.perPage",modifiers:{"number":true}}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.paginationConf, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("1")]),_c('option',{attrs:{"value":"9"}},[_vm._v("9")]),_c('option',{attrs:{"value":"12"}},[_vm._v("12")]),_c('option',{attrs:{"value":"24"}},[_vm._v("24")]),_c('option',{attrs:{"value":"48"}},[_vm._v("48")]),_c('option',{attrs:{"value":"72"}},[_vm._v("72")]),_c('option',{attrs:{"value":"98"}},[_vm._v("98")]),_c('option',{attrs:{"value":"120"}},[_vm._v("120")])])]),_c('div',{staticClass:"view-change"},[_c('div',{staticClass:"grid-view",on:{"click":function($event){_vm.paginationConf.isGrid = true}}},[_c('i',{staticClass:"fas fa-th-large"})]),_c('div',{staticClass:"list-view",on:{"click":function($event){_vm.paginationConf.isGrid = false}}},[_c('i',{staticClass:"fas fa-list"})])])])]),(_vm.category.children_count == 0)?_c('b-row',[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("no_products_found"))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }